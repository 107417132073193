import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { productFetchAsync, productUpdatehAsync, uploadImageClothAsync } from '../../features/Product/productSlice';
import toast from 'react-hot-toast';

const TableofProduct = ({data}) => {
    const {image, name, price, service_name, id,cloth_items_id} = data || {}
    const [isEditmode, setisEditmode] = useState(false);
    const dispatch = useDispatch()
    const [productData, setproductData] = useState({
        name:name,
        price:price,
        service_name:service_name,
        cloth_id:cloth_items_id,
    });

    const daveData = ()=>{
        dispatch(productUpdatehAsync(productData)).unwrap().then((res)=>{
          if(res?.status!==200){
            toast.error("Update Failed");
             return false;
          }
          toast.success("Update Succesfull");
          dispatch(productFetchAsync())
        })
    }

    const imageUpload = (imageFile)=>{
        dispatch(uploadImageClothAsync({image:imageFile,cloth_id:cloth_items_id})).unwrap().then((res)=>{
            if(res?.status!==200){
                toast.error("Update Failed");
                 return false;
            }
            toast.success("Upload Succesfull");
            dispatch(productFetchAsync())
        })
        // console.log(imageFile);
    }
   
    return (
        <tr>
            
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex items-center">
                    <div className="flex-shrink-0 w-10 h-10 relative">
                        <img className="w-full h-full rounded-full"
                            src={'https://dhopaelo.com/app/cloths/'+image}
                            alt="" />
                        {isEditmode&&<input type='file' onChange={(e)=>imageUpload(e.target.files[0])} className='absolute w-10 cursor-pointer h-11 z-50 opacity-0 top-0 bg-black' />}
                        {isEditmode&&<div className='text-xs absolute h-11 top-0 w-full flex justify-center items-center' style={{backgroundColor:'rgba(0,0,0,0.6)'}}>
                            <span>🖼</span>
                        </div>}
                    </div>
                    <div className="ml-3">
                        {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap border-b">
                        {id}. {name}
                        </p>}

                        {isEditmode&&<input onChange={(v)=>setproductData({...productData,name:v.target.value})} className='border-2 w-full p-2' placeholder='Cloths name' value={productData?.name} />}
                        
                    </div>
                </div>
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap">{price}</p>}
                {isEditmode&&<input onChange={(v)=>setproductData({...productData,price:v.target.value})} className='border-2 w-full p-2' placeholder='Cloths name' value={productData?.price} />}
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap">
                    {service_name}
                </p>}
                {isEditmode&&<input onChange={(v)=>setproductData({...productData,service_name:v.target.value})} className='border-2 w-full p-2' placeholder='Cloths name' value={productData?.service_name} />}
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                {!isEditmode&&<span
                    className="relative inline-block bg-green-200 rounded-full px-3 py-1 font-semibold text-green-900 leading-tight cursor-pointer" onClick={()=>setisEditmode((prev)=>!prev)}>
                    
                    <span className="relative">Edit</span>
                </span>}
                {isEditmode&&<span
                    
                    className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight cursor-pointer bg-green-600 rounded-full" onClick={daveData}>
                    <span className="relative text-white">Save</span>
                </span>}
                {isEditmode&&<span
                    
                    className="relative inline-block px-3 py-1 font-semibold text-red-300 leading-tight cursor-pointer bg-red-400 ml-2 rounded-full" onClick={()=>setisEditmode(false)}>
                    <span className="relative text-white">Cancel</span>
                </span>}
            </td>
        </tr>
    );
}

export default TableofProduct;
