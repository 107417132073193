import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { orderFetchAsync } from '../../features/order/orderSlice';

const Report = () => {
    const dispatch = useDispatch()
    const today = moment();
    const thirtyDaysAgo = moment().subtract(30, 'days');
    // Format the date (optional)
    const formattedDate = today.format('YYYY-MM-DD');
    const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');

    const {orders, isError, isLoading, error} = useSelector((state)=>state?.orders || {});
    const [selectedDateRange, setselectedDateRange] = useState({from:thirtyDaysAgoFormatted, to:formattedDate});
    const [labelNames, setlabelNames] = useState([]);
    const [totlaOrderPerday, settotlaOrderPerday] = useState([]);
    const [totalSlaes, settotalSlaes] = useState([]);
    const [graphinsLoading, setgraphinsLoading] = useState(false);
    const [CategoryPIData, setCategoryPIData] = useState([]);
    useEffect(() => {
        dispatch(orderFetchAsync())
    }, [dispatch]);

    //get the data of order per category
    function serviceWiseClification(){
        
        let orderPercat={
            wash:0,
            iron:0,
            washIron:0,
            dwashIron:0
        };
      
      
        orders?.forEach((order)=>{
            const {products} = order || {}
            const allProduc = JSON.parse(products);
            
            allProduc.forEach(order1 => {
               
                if(order1?.service_name?.toLowerCase()==='wash'){
                 
                    orderPercat= {...orderPercat,wash:orderPercat.wash+1}
                }else if(order1?.service_name?.toLowerCase()==='iron'){
                  
                    orderPercat= {...orderPercat,iron:orderPercat.iron+1}
                }else if(order1?.service_name?.toLowerCase()==='Wash and Iron'){
                   
                    orderPercat= {...orderPercat,washIron:orderPercat.washIron+1}
                }else if(order1?.service_name?.toLowerCase()==='Dry Wash and Iron'){
                    
                    orderPercat= {...orderPercat,dwashIron:orderPercat.dwashIron+1}
                }
            });
            
        })
        setCategoryPIData(orderPercat)
        
    }
    const levelCreate = ()=>{
        //create object of the date
        
        try {
            setgraphinsLoading(true)


            


            const fromDatevalue = new Date(selectedDateRange?.from);
            const toDatevalue = new Date(selectedDateRange?.to);
            const differenceInMilliseconds = toDatevalue-fromDatevalue;
            // Convert milliseconds to days (1 day = 24 hours, 1 hour = 60 minutes, 1 minute = 60 seconds, 1 second = 1000 milliseconds)
            const millisecondsInADay = 24 * 60 * 60 * 1000;
            const differenceInDays = differenceInMilliseconds / millisecondsInADay;
            let dateNewArr=[]
            let TotalOrders=[]
            let TotalSalesAmmount=[]
            for (let i = 0; i < differenceInDays; i++) {
                // if(i>30){
                //     continue;
                // }
                let initialTotal=0;
                let initialOrder=0;
                
                //add a day with this date
                fromDatevalue.setDate(fromDatevalue.getDate() + 1)

                let NewDate = fromDatevalue.toISOString().split('T')[0];

                let filteredOrder = orders.filter((order)=>{
                    
                    if(NewDate===order.order_date.split('T')[0]){
                        initialTotal+=order?.total_account
                        initialOrder+=1;
                        return order;
                    }
                    // if(order?.service)
                    
                    // console.log(NewDate,order.order_date.split('T')[0]);
                })

                
            
                dateNewArr.push(NewDate)
                TotalOrders.push(initialOrder)
                TotalSalesAmmount.push(initialTotal/100)
               
            }
           
            setlabelNames(dateNewArr)
            settotlaOrderPerday(TotalOrders)
            settotalSlaes(TotalSalesAmmount)

           
        

        } catch (error) {
            return <div><p>{error}</p></div>
        }
        finally{
            setgraphinsLoading(false)
        }
        

    }

    useEffect(() => {
        levelCreate()
        serviceWiseClification()
       
    }, []);
    
    setTimeout(() => {
        levelCreate()
        serviceWiseClification()
    }, 1000);
    

    const fromValueSet =(value,vof)=>{
        if(vof===1){
            setselectedDateRange({...selectedDateRange, from:value})
        }else{
            setselectedDateRange({...selectedDateRange, to:value})
        }
        
       
    }
    return (
        <div className='p-5'>
            <div className='p-4 my-4 rounded-sm bg-blue-100'>
                <div>
                    <p>Select Date:</p>
                    From <input value={selectedDateRange?.from} onChange={v=>fromValueSet(v.target.value,1)} className='px-3 rounded-sm'  type='date'/> To <input  onChange={v=>fromValueSet(v.target.value,2)} value={selectedDateRange?.to} className='px-3 rounded-sm' type='date'/>
                    <span onClick={levelCreate} className='px-4 py-1 bg-blue-600 hover:bg-blue-400 cursor-pointer transition-all rounded-md text-white ml-5'>View</span>
                </div>
            </div>
            <div className='p-4 w-full bg-blue-50 rounded-sm'>
                <div className='bg-blue-200 p-3 w-fit rounded-sm'>
                    <p>Date: <b>{selectedDateRange?.from}</b> to <b>{selectedDateRange?.to}</b></p>
                    <p>Total Sales: <b>{Math.floor(totalSlaes.reduce((accumulator, currentValue) => accumulator + currentValue, 0)*100)} BDT</b> on {labelNames.length} days</p>
                    <p>Total Orders <b>{totlaOrderPerday.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</b> on {labelNames.length} days</p>
                </div>
                {(graphinsLoading||isLoading)&&!totlaOrderPerday&&<p className='text-center'>Loading...</p>}
                {!graphinsLoading&&!isLoading&&totlaOrderPerday.length>0&&<Line
                    datasetIdKey='id'
                    data={{
                        labels: labelNames,
                        datasets: [
                        {
                            id: 1,
                            label:"Orders",
                            data: totlaOrderPerday || [1,3,6,1,3,6,9],
                        },
                        {
                            id: 2,
                            label: "Total Sale",
                            data: totalSlaes || [1,3,6,1,3,6,9],
                            color:'red'
                        },
                        
                        ],
                    }}
                />}
            </div><br></br>

            <div className='p-4 w-full bg-red-50 rounded-sm' >
                <div className='bg-red-200 p-3 w-fit rounded-sm'>
                    <p>Total Order based on Category</p>
                </div>
                {(graphinsLoading||isLoading)&&!totlaOrderPerday&&<p className='text-center'>Loading...</p>}
                <div className='flex justify-center' style={{maxHeight:'500px'}}>
                {!graphinsLoading&&!isLoading&&<Doughnut
                    options={{
                        responsive: true,
                    
                      }}
                    data={{
                        labels: ['Wash', 'Iron', 'Wash & Iron', 'Dry Wash & Iron'],
                        datasets: [
                          {
                            data: [CategoryPIData?.wash,CategoryPIData?.iron,CategoryPIData?.washIron,CategoryPIData?.dwashIron], // Values for each segment
                            backgroundColor: ['red', 'blue', 'green','yellow'], // Colors for each segment
                          },
                        ],
                      }}
                />}
                </div>
                
            </div>

        </div>
    );
}

export default Report;
