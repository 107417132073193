import React from 'react';
import ProductTable from '../Components/Product/Product_table';

const Products = () => {
    return (
        <div className='w-full lg:w-10/12 overflow-hidden h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
                <ProductTable/>
        </div>
    );
}

export default Products;
