import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading';
import TableRowOrders from './TableRowOrders';
import { orderFetchAsync } from '../../features/order/orderSlice';
import SocketIOClient from 'socket.io-client';
import toast from 'react-hot-toast';


const Hometable = () => {
    const dispatch = useDispatch()
    const [filterbyStatus, setfilterbyStatus] = useState(true);
    const [filterbypaymnet, setfilterbypaymnet] = useState(true);
    const [orders, setorders] = useState([]);
    const {orders:allorders, isError, isLoading, error} = useSelector((state)=>state?.orders || {});
   
    const [serchword, setserchword] = useState('');
    useEffect(() => {
        dispatch(orderFetchAsync()).unwrap().then((result)=>{
            setorders(result)
        })
    }, [dispatch]);

    useEffect(() => {
        try {
            const socket = SocketIOClient('https://dhopaelo.com/', {
            path: '/api/socket.io',
            });

            socket.on(`savedorder`, (msg) => {
                console.log(63,msg?.result);
                dispatch(orderFetchAsync()).unwrap().then((result)=>{
                    toast.success("New Order Arrives")
                    setorders(result)
                })
                
            });
        } catch (error) {
            console.log(error);
        }
        
    }, []);

    let content;
    
    if(!isLoading && isError){
        return <Loading message={error}/>
    }
    if(!isLoading && !isError){
        
        content = orders
        .filter((order)=>{
            console.log(filterbyStatus);
            if(order?.status===filterbyStatus){
                return order;
            }else if(filterbyStatus==='true' || filterbyStatus===true){
                return order;
            }
        })
        .filter((order)=>{
            console.log(filterbyStatus);
            if(order?.payment_status===filterbypaymnet || order?.payment_type===filterbypaymnet){
                return order;
            }else if(filterbypaymnet==='true' || filterbypaymnet===true){
                return order;
            }
        })
        .filter((order)=>{
            if(serchword===''){
                return order;
            }
            if(serchword !=='' && (order?.username.toLowerCase().indexOf(serchword.toLowerCase())> -1 || order?.email.toLowerCase().indexOf(serchword.toLowerCase())> -1 || order?.phone.toLowerCase().indexOf(serchword.toLowerCase())> -1 )){
                return order;
            }
        })
        .map((order, index)=> <TableRowOrders key={index} data={order}/>)
    }

    return (
        
        <div className="antialiased font-sans bg-gray-200 min-h-screen">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                    <div>
                        <h2 className="text-2xl font-semibold leading-tight text-center">Orders</h2>
                    </div>
                    {
                        
                        isLoading && !isError&&<div className='h-10'><p className='text-center text-green-500 text-xl'>Loading...</p> <br></br><br></br></div>
                        
                    }
                    <div className="my-2 flex sm:flex-row flex-col">
                        <div className="flex flex-row mb-1 sm:mb-0">
                            <div className="relative">
                                <select
                                    onChange={(v)=>setfilterbypaymnet(v.target.value)}
                                    className="appearance-none h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                    <option value={true}>ALL</option>
                                    <option value={'Paid'}>Paid</option>
                                    <option value={'Unpaid'}>Unpaid</option>
                                    <option value={'COD'}>COD</option>
                                    <option value={'Payment'}>Online Pay</option>
                                   
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="relative">
                                <select
                                    onChange={(v)=>setfilterbyStatus(v.target.value)}
                                    className="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500">
                                    <option value={true}>All</option>
                                    <option value={'Pending'}>Pending</option>
                                    <option value={'Ongoing'}>Ongoing</option>
                                    <option value={'Washing'}>Washing</option>
                                    <option value={'Drying'}>Drying</option>
                                    <option value={'Cleaning'}>Cleaning</option>
                                    <option value={'On Delivery'}>On Delivery</option>
                                    <option value={'Completed'}>Completed</option>
                                    <option value={'Deliverd'}>Deliverd</option>
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="block relative">
                            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                                    <path
                                        d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                                    </path>
                                </svg>
                            </span>
                            <input placeholder="Search"
                                onChange={(v)=>setserchword(v.target.value)}
                                className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                        </div>
                    </div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Customer
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Orders Item
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Pickup & Delivery
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Vendor
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Details
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content}
                                    
                                </tbody>
                            </table>
                            <div
                                className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Showing 1 to 4 of 50 Entries
                                </span>
                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                                        Prev
                                    </button>
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
    );
}

export default Hometable;
