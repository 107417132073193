import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { orderData, orderUpdate } from "./orderAPI";

const initialState = {
    orders:[],
    isLoading:false,
    isError: false,
    error:''
}


//async thunk
export const orderFetchAsync = createAsyncThunk(
    'order/fetchorder',
    async ()=>{
        const responce = await orderData();

        return responce;
    }
);
//async thunk
export const orderUpdateAsync = createAsyncThunk(
    'order/updateorder',
    async (data)=>{
        const responce = await orderUpdate(data);

        return responce;
    }
);

export const orderSlice = createSlice({
    name:'orders',
    initialState,
    extraReducers: (builder)=>{
        builder
            .addCase(orderFetchAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(orderFetchAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                state.orders = action?.payload;
            })
            .addCase(orderFetchAsync.rejected, (state, action)=>{
                state.orders=[];
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;

            })

            .addCase(orderUpdateAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(orderUpdateAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                
                const finDupdatedId = state.orders?.findIndex((t)=>t.id===action.payload[0]?.id);
                // console.log(action?.payload,finDupdatedId);
                // console.log({...state.orders});
                console.log(action.payload);
                state.orders[finDupdatedId] = action.payload[0];
            })
            .addCase(orderUpdateAsync.rejected, (state, action)=>{
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;

            })
    }
});



export default orderSlice?.reducer;