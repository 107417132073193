import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
import './App.css';
import Users from './Pages/Users';
import Home from './Pages/Home';
import { Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import SideNav from './Components/SideNav';
import toast, { Toaster } from 'react-hot-toast';
import Login from './Components/User/Login';
import { useDispatch, useSelector } from 'react-redux';
import { ref_login } from './features/login/loginSlice';
import Products from './Pages/Products';
import Reports from './Pages/Reports';
import Vendors from './Pages/Vendors';
import Setting from './Pages/Setting';



function App() {
  // const [isLogin, setisLogin] = useState(false);
  const {isError, error, isLoading,user, isLogin} = useSelector((state)=>state?.login)
  const dispatch = useDispatch()
  console.log(isLogin);
  useEffect(() => {
    
    const data = JSON.parse(sessionStorage.getItem('loggeduser'))
    console.log(data);
    if(data?.id){
      dispatch(ref_login(data))
    }

  }, []);
  
  return (
    <div className="flex w-full">
      
      <Router>
        {!isLogin&&<Login/>}
        
        {isLogin&&<SideNav/>}
        <Routes>
          {isLogin&&<>
            <Route index path='/' element={<Home/>}/>
            <Route path='/users' element={<Users/>}/>
            <Route path='/products' element={<Products/>}/>
            <Route path='/reports' element={<Reports/>}/>
            <Route path='/vendors' element={<Vendors/>}/>
            <Route path='/setting' element={<Setting/>}/>

          </>}
          
        </Routes>
       
      </Router>
      <div>
      <Toaster />
    </div>
    </div>
  );
}

export default App;
