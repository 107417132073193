import React from 'react';
import Allsettings from '../Components/Settings/Allsettings';

const Setting = () => {
    return (
        <div className='w-full lg:w-10/12 overflow-hidden h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
            <Allsettings/>
        </div>
       
    );
}

export default Setting;
