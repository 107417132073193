import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { loginData } from "./loginAPI";

const initialState = {
    user:{},
    isLoading:false,
    isError: false,
    error:'',
    isLogin:false
}


//async thunk
export const loginFetchAsync = createAsyncThunk(
    'user/login',
    async (data)=>{
        const responce = await loginData(data);

        return responce;
    }
);

export const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers:{
        ref_login:(state,action)=>{
            state.isLoading = false;
            state.user = action?.payload;
            state.isLogin = true;
            state.error = '';
        },
        ref_logout:(state)=>{
            state.isLoading = false;
            state.user = {}
            state.isLogin = false;
            state.error = '';
        }
    },
    extraReducers: (builder)=>{
        builder
            .addCase(loginFetchAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(loginFetchAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                state.user = action?.payload;
                state.isLogin = true;
                state.error = '';
            })
            .addCase(loginFetchAsync.rejected, (state, action)=>{
                state.user={};
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;
                state.isLogin = false;
            })
    }
});


export const { ref_login, ref_logout } = loginSlice.actions;
export default loginSlice?.reducer;