import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { productFetchAsync, productUpdatehAsync, uploadImageClothAsync } from '../../features/Product/productSlice';
import toast from 'react-hot-toast';
import { API_URL } from '../../utils/lib';

import { VendorFetchAsync } from '../../features/vendor/vendorSlice';

const ModalInformation = ({data}) => {
    const {image, name, price, service_name, id,cloth_items_id, logo:photo, phone, email, fnid:nidf, bnid:nidb} = data || {}
    console.log(data);
    const [isEditmode, setisEditmode] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [selectedFile, setselectedFile] = useState(null);
    const dispatch = useDispatch();

    const handleFileChange = (e,type) => {
      const file = e.target.files[0];
      handleSubmit(file,type)
    };

    const handleSubmit = async (file,type) => {
      const formData = new FormData();
      formData.append("image", file);
  
      try {
        setisLoading(true);
        const response = await fetch(API_URL + "/vendor_image/" + id+"/"+type, {
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          if (data.status !== 200) {
            toast(data.message);
            setisLoading(false);
            return 0;
          }
          dispatch(VendorFetchAsync())
          toast(data.message);
          
          // Implement additional logic, such as updating state or displaying a success message
        } else {
          toast("Upload Failed");
          // console.error('Image upload failed',response);
          setisLoading(false);
          // Handle error, display error message, etc.
        }
      } catch (error) {
        setisLoading(false);
      
        console.error("Error uploading image:", error);
        // Handle error, display error message, etc.
      }
    };
    
   
    return (
    <div className="max-w-2xl mx-auto p-4 lg:p-8 bg-white h-fit w-full text-center rounded-md mt-5">
      <h2 className="text-2xl font-bold mb-4">User Information</h2>
      
      <div className='flex justify-center w-full flex-wrap'>
        <div className='text-left w-full lg:w-1/2'>
          <p className="mb-2 text-left">
            <strong className="font-semibold">Name:</strong> {name}
          </p>
          <p className="mb-2 text-left">
            <strong className="font-semibold">Email:</strong> {email}
          </p>
          <p className="mb-2 text-left">
            <strong className="font-semibold">Phone:</strong> {phone}
          </p>
        </div>
        
        <div className="mb-2 w-full lg:w-1/2 justify-end flex items-center">
          <form className='relative h-24 w-24 border-2'>
            {photo &&!isLoading&&<img className="h-full w-full" src={photo} alt="User" />}
            {isLoading&&<img className="h-full w-full" src={photo} alt="User" />}
            <div className='absolute top-0 left-0 w-full h-full hover:bg-black transition-all opacity-0 hover:opacity-60 cursor-pointer'>
              <p className='text-white'>Change Photo</p>
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                className='h-full w-full opacity-0'
                onChange={(e)=>handleFileChange(e,'logo')}
              />
              
            </div>
          </form>
          
        </div>
      </div>
      <div className='flex justify-around mt-8'>
        <div className="mb-2">
          <strong className="font-semibold">NID Front Photo:</strong>{' '}
          
          <div className='relative h-44 w-44 border-2'>
            {nidf &&!isLoading&& <img className="h-full w-full" src={nidf} alt="User" />}
            {isLoading&&<p>Loadding...</p>}
            <form className='absolute top-0 left-0 w-full h-full hover:bg-black transition-all opacity-0 hover:opacity-60 cursor-pointer'>
              <p className='text-white'>Change Photo</p>
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                className='h-full w-full opacity-0'
                onChange={(e)=>handleFileChange(e,'fnid')}
              />
            </form>
          </div>
        </div>
        <div className="mb-2">
          <strong className="font-semibold">NID Back Photo:</strong>{' '}
          

          <div className='relative h-44 w-44 border-2'>
            {nidb && !isLoading&& <img className="h-full w-full" src={nidb} alt="User" />}
            {isLoading&&<p>Loading...</p>}
            <form className='absolute top-0 left-0 w-full h-full hover:bg-black transition-all opacity-0 hover:opacity-60 cursor-pointer'>
              <p className='text-white'>Change Photo</p>
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                className='h-full w-full opacity-0'
                onChange={(e)=>handleFileChange(e,'bnid')}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    );
}

export default ModalInformation;
