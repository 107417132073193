import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { vendorData, vendorUpdate } from "./vendorAPI";

const initialState = {
    vendors:[],
    isLoading:false,
    isError: false,
    error:''
}


//async thunk
export const VendorFetchAsync = createAsyncThunk(
    'vendor/fetchvendor',
    async ()=>{
        const responce = await vendorData();

        return responce;
    }
);

//async thunk
export const VendorUpdateAsync = createAsyncThunk(
    'vendor/updatevendor',
    async (data)=>{
        const responce = await vendorUpdate(data);

        return responce;
    }
);

export const vendorSlice = createSlice({
    name:'vendors',
    initialState,
    extraReducers: (builder)=>{
        builder
            .addCase(VendorFetchAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(VendorFetchAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                state.vendors = action?.payload;
            })
            .addCase(VendorFetchAsync.rejected, (state, action)=>{
                state.vendors=[];
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;

            })

            .addCase(VendorUpdateAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(VendorUpdateAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
            })
            .addCase(VendorUpdateAsync.rejected, (state, action)=>{
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;

            })
    }
});



export default vendorSlice?.reducer;