import React from 'react';
import UsersData from '../Components/User/Users_data';


const Users = () => {
    return (
        <div className='w-full lg:w-10/12 h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
            <UsersData/>
        </div>
        
    );
}

export default Users;
