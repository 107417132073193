import React, { useEffect, useState } from "react";
import TableofProduct from "../Product/TableofProduct";
import productsArr from "../../utils/products";
import ModalService from "./ModalService";
import { useDispatch } from "react-redux";
import {
  VendorFetchAsync,
  VendorUpdateAsync,
} from "../../features/vendor/vendorSlice";
import ModalInformation from "./ModalInformation";
import { API_URL } from "../../utils/lib";
import toast from "react-hot-toast";

const TableRowVendor = ({ data }) => {
  const [isLoadingsetup, setisLoadingsetup] = useState(false);

  const dispatch = useDispatch();
  let {
    full_name,
    email,
    phone,
    address,
    name: shopname,
    logo,
    location,
    available,
    approved,
    id,
    photo: image,
    services,
    v_id,
    items,
  } = data || {};
  const [isToggled, setIsToggled] = useState(!available);
  const [showService, setshowService] = useState(false);
  const [ShowInformation, setShowInformation] = useState(false);
  const [loadingquery, setloadingquery] = useState(false);
  const [qrCode, setqrCode] = useState(false);
  const qrData = {
    id: v_id,
    name: shopname,
    logo,
    location,
  };

  useEffect(() => {
    setIsToggled(!available);
  }, [available]);

  const handleToggle = () => {
    setloadingquery(true);
    const v = available === 1 ? 0 : 1;
    fetch(API_URL + "/vendors_update_allow/" + v_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ v: v }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.status === 200) {
          console.log(result);
          setIsToggled(!isToggled);
          toast.success(v == 1 ? "Allowed" : "Disallowed");
          dispatch(VendorFetchAsync());
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Found");
      })
      .finally(() => {
        setloadingquery(false);
      });
  };
  const handleDelete = (id) => {
    if(!window.confirm("Are sure to delete?")){
        return 0;
    }
    setloadingquery(true);
    
    fetch(API_URL + "/vendors_delete/" + v_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.status === 200) {
          toast.success(result?.message);
          dispatch(VendorFetchAsync());
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Found");
      })
      .finally(() => {
        setloadingquery(false);
      });
  };

  const approveingVendor = () => {
    setisLoadingsetup(true);
    const data = productsArr;
    console.log(typeof data);
    dispatch(VendorUpdateAsync({ data, id: v_id }))
      .unwrap()
      .then((result) => {
        setisLoadingsetup(false);
        dispatch(VendorFetchAsync());
      });
  };

  // const DisApprovedVendor = ()=>{
  //     setisLoadingsetup(true)
  //     const data = productsArr;
  //     console.log(typeof data);
  //     dispatch(VendorUpdateAsync({data, id:v_id})).unwrap().then((result)=>{
  //         setisLoadingsetup(false)
  //         dispatch(VendorFetchAsync())

  //     })
  // }

  const generateQr = () => {
    setqrCode((prev) => !prev);
  };
  return (
    <tr>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10">
            <img
              className="w-full h-full rounded-full"
              src={
                !logo
                  ? "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  : logo
              }
              alt=""
            />
          </div>
          <div className="ml-3">
            <p className="text-gray-900 whitespace-no-wrap border-b">
              {id}. {full_name}
            </p>
            <p className="text-gray-900 whitespace-no-wrap border-b">{email}</p>
            <p className="text-gray-900 whitespace-no-wrap">{phone}</p>
          </div>
        </div>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10">
            {/* <img className="w-full h-full rounded-full"
                            src={logo}
                            alt={'Not found'} /> */}
          </div>
          <div className="ml-3">
            <p className="text-gray-900 whitespace-no-wrap border-b">
              {shopname}
            </p>
            <p className="text-gray-900 whitespace-no-wrap border-b mb-1">
              {location}
            </p>

            {approved === "Approved" && (
              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                <span
                  aria-hidden
                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                ></span>
                <span className="relative">Approved</span>
              </span>
            )}
            {approved !== "Approved" && (
              <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                <span
                  aria-hidden
                  className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                ></span>
                <span className="relative">Not Approved</span>
              </span>
            )}
          </div>
        </div>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">
          <span
            onClick={() => setshowService((prev) => !prev)}
            className="relative inline-block cursor-pointer border-gray-200 px-3 m-1 py-1 font-semibold text-green-900 leading-tight"
          >
            <span
              aria-hidden
              className="absolute inset-0 text-xs bg-green-600 opacity-50 rounded-full"
            ></span>
            <span className="relative text-xs">All Services</span>
          </span>
          <span
            onClick={() => setShowInformation((prev) => !prev)}
            className="relative inline-block cursor-pointer border-blue-200 m-1 px-3 py-1 font-semibold text-green-900 leading-tight ml-2"
          >
            <span
              aria-hidden
              className="absolute inset-0 text-xs bg-blue-600 opacity-50 rounded-full"
            ></span>
            <span className="relative text-xs">Information</span>
          </span>
          <br></br>
          {qrCode && (
            <div
              className="absolute top-0 z-50 left-0 h-screen w-full flex justify-center items-center"
              style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
            >
              <div className="w-full lg:w-1/2 p-10 text-center bg-white rounded-md">
                <p className="mb-2">
                  Qr Code for <b>{shopname}</b>
                </p>
                <img
                  className="mx-auto border-2"
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=${JSON.stringify(
                    qrData
                  )}&amp;size=250x250`}
                  alt=""
                  title=""
                />
                <br></br>
                <a
                  target="_blank"
                  href={`https://api.qrserver.com/v1/create-qr-code/?data=${JSON.stringify(
                    qrData
                  )}&amp;size=250x250`}
                  className="text-center px-3 py-2 bg-blue-400 rounded border-2"
                  onClick={generateQr}
                >
                  Download
                </a>
                <button
                  className="text-center px-3 py-2 ml-2 bg-red-400 rounded border-2"
                  onClick={generateQr}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <span
            onClick={generateQr}
            className="relative inline-block cursor-pointer border-blue-200 m-1 px-3 py-1 font-semibold text-green-900 leading-tight mt-2"
          >
            <span
              aria-hidden
              className="absolute inset-0 text-xs bg-blue-600 opacity-50 rounded-full"
            ></span>
            <span className="relative text-xs">Generate QR</span>
          </span>
        </p>
        {showService && (
          <div className="fixed h-screen w-full bg-white top-0 left-0 z-50 overflow-y-auto flex justify-center px-2 lg:px-10">
            <table className="min-w-full leading-normal">
              <th className="p-3" colSpan={3}>
                <tr
                  onClick={() => setshowService((prev) => !prev)}
                  className="cursor-pointer flex justify-between"
                >
                  <p>Close</p>
                  <div className="flex items-center">
                    <div className="flex-shrink-0 w-10 h-10">
                      <img
                        className="w-full h-full rounded-full"
                        src={
                          !logo
                            ? "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                            : logo
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-gray-900 whitespace-no-wrap border-b">
                        {shopname}
                      </p>
                      <p className="text-gray-900 whitespace-no-wrap border-b mb-1">
                        {location}
                      </p>
                    </div>
                  </div>
                </tr>
              </th>
              <tbody>
                {items && JSON.parse(items) ? (
                  JSON.parse(items)?.map((product) => (
                    <ModalService data={product} />
                  ))
                ) : (
                  <p className="text-center mt-5">Not Found</p>
                )}
              </tbody>
            </table>
          </div>
        )}

        {ShowInformation && (
          <div
            className="fixed h-screen w-full top-0 left-0 z-50 overflow-y-auto flex justify-center px-2 lg:px-10"
            style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
          >
            <button
              onClick={() => setShowInformation((prev) => !prev)}
              className="bg-red-500 px-5 py-2 rounded absolute top-4 left-3"
            >
              Close
            </button>
            <ModalInformation data={data} />
          </div>
        )}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div
          onClick={handleToggle}
          className={`${
            isToggled ? "bg-gray-400 justify-start" : "bg-green-400 justify-end"
          } w-12 p-1 rounded-full flex transition-all`}
        >
          <div className={`h-4 w-4 bg-white rounded-full transition-all`}></div>
        </div>
        <button onClick={handleToggle}>
          {isToggled ? "Disallowed" : "Allowed"}
        </button>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="" onClick={approveingVendor}>
          <button className="relative text-xs inline-block cursor-pointer bg-blue-600 hover:bg-blue-400 transition-all rounded border-blue-800 border-2 px-3 py-1 font-semibold text-green-100 leading-tight ml-2">
            {isLoadingsetup ? "Loading..." : "Approve"}
          </button>
        </div>
        <p>
          <span
            onClick={() => handleDelete(id)}
            className="relative inline-block cursor-pointer border-gray-200 px-3 m-1 py-1 font-semibold text-red-900 leading-tight"
          >
            <span
              aria-hidden
              className="absolute inset-0 text-xs bg-red-600 opacity-50 rounded-full"
            ></span>
            <span className="relative text-xs">DELETE</span>
          </span>
        </p>
      </td>
    </tr>
  );
};

export default TableRowVendor;
