import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ref_logout } from '../features/login/loginSlice';

const SideNav = () => {
    const [leftPositionedValue, setleftPositionedValue] = useState('-left-96');
    const dispatch = useDispatch()

    const logoutFun = ()=>{
        sessionStorage.removeItem('loggeduser')
        dispatch(ref_logout())
    }

    const openHideSidebar = ()=>{
        if(leftPositionedValue==='-left-0'){
            setleftPositionedValue('-left-96')
        }else{
            setleftPositionedValue('-left-0')
        }
        
    }
    return (
        <>
        <div className='p-3 bg-white absolute top-0 left-0 w-full block lg:hidden'>
            <p className='cursor-pointer flex justify-end' onClick={openHideSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </p>
        </div>
        <div className={`${leftPositionedValue==='-left-96'?'w-0 lg:w-2/12':'w-11/12 p-0 lg:p-4 transition-all'} bg-white`} style={{boxShadow:'0px 0px 4px 0px grey'}}>
            <div className={`fixed z-50 overflow-hidden  ${leftPositionedValue} lg:-left-0 transition-all ml-3`}>
                <div className='border-b p-4'>
                    <p>Admin Dashboard</p>
                </div>
                <div className='text-left list-none space-y-2 mt-5'>
                    <Link to={'/'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Home</p></li>
                    </Link>

                    <Link to={'/users'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Users</p></li>
                    </Link>

                    <Link to={'/products'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Products</p></li>
                    </Link>
                    <Link to={'/reports'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Statistics</p></li>
                    </Link>
                    <Link to={'/vendors'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Vendors</p></li>
                    </Link>
                    <Link to={'/setting'}>
                        <li className='border-b p-2 hover:bg-slate-400'><p>Settings</p></li>
                    </Link>


                    <Link>
                        <li onClick={logoutFun} className='border-b p-2 hover:bg-slate-400'><p>Logout</p></li>
                    </Link>

                
                </div>
            </div>
            
        </div>
        </>
    );
}

export default SideNav;
