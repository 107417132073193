import React from 'react';
import Report from '../Components/Reports/report';

const Reports = () => {
    return (
        <div className='w-full lg:w-10/12 overflow-hidden h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
                <Report/>
        </div>
    );
}

export default Reports;
