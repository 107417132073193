import React from 'react';
import VendorsData from '../Components/Vendor/Vendor_data';


const Vendors = () => {
    return (
        <div className='w-full lg:w-10/12 h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
            <VendorsData/>
        </div>
        
    );
}

export default Vendors;
