const productsArr = [
  {
    id: 1,
    name: "T-Shirt",
    image: "t-shirt.jpg",
    cloth_items_id: 1,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 2,
    name: "Ladies Apron",
    image: "Apron.png",
    cloth_items_id: 2,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 3,
    name: "Track Suit (2 piece)",
    image: "Track Suit.png",
    cloth_items_id: 3,
    service_name: "Wash",
    price: 80,
  },
  {
    id: 4,
    name: "Panjabi",
    image: "Panjabi.png",
    cloth_items_id: 4,
    service_name: "Wash",
    price: 70,
  },
  {
    id: 5,
    name: "Parda (more than 6'')",
    image: "Parda.png",
    cloth_items_id: 5,
    service_name: "Wash",
    price: 240,
  },
  {
    id: 6,
    name: "Jamdani Saree",
    image: "Jamdani Sharee.png",
    cloth_items_id: 6,
    service_name: "Wash",
    price: 160,
  },
  {
    id: 7,
    name: "Ladies Shirt",
    image: "Ladies Shirt.png",
    cloth_items_id: 7,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 8,
    name: "Ladies Pajama",
    image: "Pajama.png",
    cloth_items_id: 8,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 9,
    name: "Jamdani Silk Saree",
    image: "Jamdani Sharee slik.png",
    cloth_items_id: 9,
    service_name: "Wash",
    price: 160,
  },
  {
    id: 10,
    name: "Saree S Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 10,
    service_name: "Wash",
    price: 160,
  },
  {
    id: 11,
    name: "Jacket Normal",
    image: "jacket.png",
    cloth_items_id: 11,
    service_name: "Wash",
    price: 170,
  },
  {
    id: 12,
    name: "Borka Normal",
    image: "borka.png",
    cloth_items_id: 12,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 13,
    name: "Ladies Pants",
    image: "Ladies Pant.png",
    cloth_items_id: 13,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 14,
    name: "Apron",
    image: "Apron.png",
    cloth_items_id: 14,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 15,
    name: "Parda (Less than 3'')",
    image: "Parda.png",
    cloth_items_id: 15,
    service_name: "Wash",
    price: 100,
  },
  {
    id: 16,
    name: "Plazu",
    image: "Plazu.png",
    cloth_items_id: 16,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 17,
    name: "Towel (Hand)",
    image: "Tawell(hand).png",
    cloth_items_id: 17,
    service_name: "Wash",
    price: 40,
  },
  {
    id: 18,
    name: "Kammez (3 piece)",
    image: "kameez.jpg",
    cloth_items_id: 18,
    service_name: "Wash",
    price: 150,
  },
  {
    id: 19,
    name: "Shirt",
    image: "Shirt.png",
    cloth_items_id: 19,
    service_name: "Wash",
    price: 70,
  },
  {
    id: 20,
    name: "Lungi",
    image: "Lungi.png",
    cloth_items_id: 20,
    service_name: "Wash",
    price: 50,
  },
  {
    id: 21,
    name: "Towel (Face)",
    image: "hand tawell(small).png",
    cloth_items_id: 21,
    service_name: "Wash",
    price: 40,
  },
  {
    id: 22,
    name: "Sufari Suit (2 piece)",
    image: "Sufari Top.png",
    cloth_items_id: 22,
    service_name: "Wash",
    price: 80,
  },
  {
    id: 23,
    name: "Borka Special",
    image: "borka.png",
    cloth_items_id: 23,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 24,
    name: "Nokshi Katha (Large)",
    image: "Nakshi Khatha.png",
    cloth_items_id: 24,
    service_name: "Wash",
    price: 200,
  },
  {
    id: 25,
    name: "Nokshi Katha (small)",
    image: "Nakshi Khatha(small).png",
    cloth_items_id: 25,
    service_name: "Wash",
    price: 150,
  },
  {
    id: 26,
    name: "Parda (Less than 4'')",
    image: "Parda.png",
    cloth_items_id: 26,
    service_name: "Wash",
    price: 140,
  },
  {
    id: 27,
    name: "Pajama",
    image: "Pajama.png",
    cloth_items_id: 27,
    service_name: "Wash",
    price: 70,
  },
  {
    id: 28,
    name: "Bed Sheet (small)",
    image: "Bed Sheet.png",
    cloth_items_id: 28,
    service_name: "Wash",
    price: 70,
  },
  {
    id: 29,
    name: "Orna/Dopatta",
    image: "Orna.png",
    cloth_items_id: 29,
    service_name: "Wash",
    price: 50,
  },
  {
    id: 30,
    name: "Pillow Cover",
    image: "Pillow Cover.png",
    cloth_items_id: 30,
    service_name: "Wash",
    price: 40,
  },
  {
    id: 31,
    name: "Saree Georgette",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 31,
    service_name: "Wash",
    price: 80,
  },
  {
    id: 32,
    name: "Towel (Large)",
    image: "Tawell(hand).png",
    cloth_items_id: 32,
    service_name: "Wash",
    price: 40,
  },
  {
    id: 33,
    name: "Ladies Jacket Wool",
    image: "Ladies Jacket.png",
    cloth_items_id: 33,
    service_name: "Wash",
    price: 160,
  },
  {
    id: 34,
    name: "Hijab",
    image: "hijab.png",
    cloth_items_id: 34,
    service_name: "Wash",
    price: 30,
  },
  {
    id: 35,
    name: "Blaouse",
    image: "blaouse.png",
    cloth_items_id: 35,
    service_name: "Wash",
    price: 30,
  },
  {
    id: 36,
    name: "Towel (small)",
    image: "hand tawell(small).png",
    cloth_items_id: 36,
    service_name: "Wash",
    price: 30,
  },
  {
    id: 37,
    name: "Mosharee",
    image: "Mosharee.png",
    cloth_items_id: 37,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 38,
    name: "Ladies Top",
    image: "Ladies-Top.jpg",
    cloth_items_id: 38,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 39,
    name: "Pants/Trouser",
    image: "jeans.png",
    cloth_items_id: 39,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 40,
    name: "Kammez (2 piece)",
    image: "kameez.jpg",
    cloth_items_id: 40,
    service_name: "Wash",
    price: 120,
  },
  {
    id: 41,
    name: "Jeans",
    image: "jeans.png",
    cloth_items_id: 41,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 42,
    name: "Shorts",
    image: "shorts.png",
    cloth_items_id: 42,
    service_name: "Wash",
    price: 20,
  },
  {
    id: 43,
    name: "Panjabi Heavey",
    image: "Panjabi.png",
    cloth_items_id: 43,
    service_name: "Wash",
    price: 100,
  },
  {
    id: 44,
    name: "Table Cloth",
    image: "table cloths.png",
    cloth_items_id: 44,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 45,
    name: "Bed Sheet (large)",
    image: "Bed Sheet.png",
    cloth_items_id: 45,
    service_name: "Wash",
    price: 100,
  },
  {
    id: 46,
    name: "Mujib Coat",
    image: "Mujib Coat.png",
    cloth_items_id: 46,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 47,
    name: "Parda (less than 6'')",
    image: "Parda.png",
    cloth_items_id: 47,
    service_name: "Wash",
    price: 220,
  },
  {
    id: 48,
    name: "Ladies Jacket",
    image: "Ladies Jacket.png",
    cloth_items_id: 48,
    service_name: "Wash",
    price: 120,
  },
  {
    id: 49,
    name: "Kammez",
    image: "kameez.jpg",
    cloth_items_id: 49,
    service_name: "Wash",
    price: 60,
  },
  {
    id: 50,
    name: "Parda (Less than 5'')",
    image: "Parda.png",
    cloth_items_id: 50,
    service_name: "Wash",
    price: 180,
  },
  {
    id: 51,
    name: "Shaal",
    image: "Shaal.png",
    cloth_items_id: 51,
    service_name: "Wash",
    price: 100,
  },
  {
    id: 52,
    name: "T-Shirt",
    image: "t-shirt.png",
    cloth_items_id: 52,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 53,
    name: "Ladies Apron",
    image: "Apron.png",
    cloth_items_id: 53,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 54,
    name: "Saree Cotton",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 54,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 55,
    name: "Coat/Blazer",
    image: "default.png",
    cloth_items_id: 55,
    service_name: "Iron",
    price: 70,
  },
  {
    id: 56,
    name: "Track Suit (2 piece)",
    image: "Track Suit.png",
    cloth_items_id: 56,
    service_name: "Iron",
    price: 20,
  },
  {
    id: 57,
    name: "Sufari Top",
    image: "Sufari Top.png",
    cloth_items_id: 57,
    service_name: "Iron",
    price: 30,
  },
  {
    id: 58,
    name: "Panjabi",
    image: "Panjabi.png",
    cloth_items_id: 58,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 59,
    name: "Parda (more than 6'')",
    image: "Parda.png",
    cloth_items_id: 59,
    service_name: "Iron",
    price: 130,
  },
  {
    id: 60,
    name: "Saree Jamdani",
    image: "Jamdani Sharee.png",
    cloth_items_id: 60,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 61,
    name: "Saree Jamdani Cotton",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 61,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 62,
    name: "Ladies Shirt",
    image: "Ladies Shirt.png",
    cloth_items_id: 62,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 63,
    name: "Ladies Pajama",
    image: "Pajama.png",
    cloth_items_id: 63,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 64,
    name: "Saree Jamdani Silk",
    image: "Jamdani Sharee slik.png",
    cloth_items_id: 64,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 65,
    name: "Saree S Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 65,
    service_name: "Iron",
    price: 80,
  },
  {
    id: 66,
    name: "Jacket Normal",
    image: "jacket.png",
    cloth_items_id: 66,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 67,
    name: "Borka Normal",
    image: "borka.png",
    cloth_items_id: 67,
    service_name: "Iron",
    price: 30,
  },
  {
    id: 68,
    name: "Ladies Pants",
    image: "Ladies Pant.png",
    cloth_items_id: 68,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 69,
    name: "Suit (2 piece)",
    image: "default.png",
    cloth_items_id: 69,
    service_name: "Iron",
    price: 100,
  },
  {
    id: 70,
    name: "Apron",
    image: "Apron.png",
    cloth_items_id: 70,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 71,
    name: "Maxi",
    image: "Maxi.png",
    cloth_items_id: 71,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 72,
    name: "Ladies Suit (2Piece)",
    image: "Ladies Suit.png",
    cloth_items_id: 72,
    service_name: "Iron",
    price: 100,
  },
  {
    id: 73,
    name: "Parda (Less than 3'')",
    image: "Parda.png",
    cloth_items_id: 73,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 74,
    name: "Plazu",
    image: "Plazu.png",
    cloth_items_id: 74,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 75,
    name: "Kammez (3 piece)",
    image: "kameez.jpg",
    cloth_items_id: 75,
    service_name: "Iron",
    price: 30,
  },
  {
    id: 76,
    name: "Shirt",
    image: "Shirt.png",
    cloth_items_id: 76,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 77,
    name: "Lungi",
    image: "Lungi.png",
    cloth_items_id: 77,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 78,
    name: "Sufari Suit (2 piece)",
    image: "Sufari Top.png",
    cloth_items_id: 78,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 79,
    name: "Borka Special",
    image: "borka.png",
    cloth_items_id: 79,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 80,
    name: "Parda (Less than 4'')",
    image: "Parda.png",
    cloth_items_id: 80,
    service_name: "Iron",
    price: 70,
  },
  {
    id: 81,
    name: "Saree Benarasi Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 81,
    service_name: "Iron",
    price: 100,
  },
  {
    id: 82,
    name: "Pajama",
    image: "Pajama.png",
    cloth_items_id: 82,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 83,
    name: "Tie",
    image: "Tie.png",
    cloth_items_id: 83,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 84,
    name: "Bed Sheet (small)",
    image: "Bed Sheet.png",
    cloth_items_id: 84,
    service_name: "Iron",
    price: 30,
  },
  {
    id: 85,
    name: "Orna/Dopatta",
    image: "Orna.png",
    cloth_items_id: 85,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 86,
    name: "Pillow Cover",
    image: "Pillow Cover.png",
    cloth_items_id: 86,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 87,
    name: "Saree Georgette",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 87,
    service_name: "Iron",
    price: 70,
  },
  {
    id: 88,
    name: "Ladies Jacket Wool",
    image: "Ladies Jacket.png",
    cloth_items_id: 88,
    service_name: "Iron",
    price: 30,
  },
  {
    id: 89,
    name: "Saree Silk",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 89,
    service_name: "Iron",
    price: 70,
  },
  {
    id: 90,
    name: "Hijab",
    image: "hijab.png",
    cloth_items_id: 90,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 91,
    name: "Blaouse",
    image: "blaouse.png",
    cloth_items_id: 91,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 92,
    name: "Saree Benarasi",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 92,
    service_name: "Iron",
    price: 100,
  },
  {
    id: 93,
    name: "Ladies Top",
    image: "Ladies-Top.jpg",
    cloth_items_id: 93,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 94,
    name: "Pants/Trouser",
    image: "jeans.png",
    cloth_items_id: 94,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 95,
    name: "Kammez (2 piece)",
    image: "kameez.jpg",
    cloth_items_id: 95,
    service_name: "Iron",
    price: 20,
  },
  {
    id: 96,
    name: "Jeans",
    image: "jeans.png",
    cloth_items_id: 96,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 97,
    name: "Panjabi Heavey",
    image: "Panjabi.png",
    cloth_items_id: 97,
    service_name: "Iron",
    price: 15,
  },
  {
    id: 98,
    name: "Saree Half Silk",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 98,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 99,
    name: "Table Cloth",
    image: "table cloths.png",
    cloth_items_id: 99,
    service_name: "Iron",
    price: 15,
  },
  {
    id: 100,
    name: "Bed Sheet (large)",
    image: "Bed Sheet.png",
    cloth_items_id: 100,
    service_name: "Iron",
    price: 40,
  },
  {
    id: 101,
    name: "Mujib Coat",
    image: "Mujib Coat.png",
    cloth_items_id: 101,
    service_name: "Iron",
    price: 25,
  },
  {
    id: 102,
    name: "Parda (less than 6'')",
    image: "Parda.png",
    cloth_items_id: 102,
    service_name: "Iron",
    price: 110,
  },
  {
    id: 103,
    name: "Ladies Jacket",
    image: "Ladies Jacket.png",
    cloth_items_id: 103,
    service_name: "Iron",
    price: 50,
  },
  {
    id: 104,
    name: "Kammez",
    image: "kameez.jpg",
    cloth_items_id: 104,
    service_name: "Iron",
    price: 10,
  },
  {
    id: 105,
    name: "Parda (Less than 5'')",
    image: "Parda.png",
    cloth_items_id: 105,
    service_name: "Iron",
    price: 90,
  },
  {
    id: 106,
    name: "T-Shirt",
    image: "t-shirt.png",
    cloth_items_id: 106,
    service_name: "Wash and Iron",
    price: 80,
  },
  {
    id: 107,
    name: "Ladies Apron",
    image: "Apron.png",
    cloth_items_id: 107,
    service_name: "Wash and Iron",
    price: 70,
  },
  {
    id: 108,
    name: "Track Suit (2 piece)",
    image: "Track Suit.png",
    cloth_items_id: 108,
    service_name: "Wash and Iron",
    price: 100,
  },
  {
    id: 109,
    name: "Panjabi",
    image: "Panjabi.png",
    cloth_items_id: 109,
    service_name: "Wash and Iron",
    price: 80,
  },
  {
    id: 110,
    name: "Parda (more than 6'')",
    image: "Parda.png",
    cloth_items_id: 110,
    service_name: "Wash and Iron",
    price: 370,
  },
  {
    id: 111,
    name: "Saree Jamdani Cotton",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 111,
    service_name: "Wash and Iron",
    price: 170,
  },
  {
    id: 112,
    name: "Ladies Shirt",
    image: "Ladies Shirt.png",
    cloth_items_id: 112,
    service_name: "Wash and Iron",
    price: 80,
  },
  {
    id: 113,
    name: "Ladies Pajama",
    image: "Pajama.png",
    cloth_items_id: 113,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 114,
    name: "Saree Jamdani Silk",
    image: "Jamdani Sharee slik.png",
    cloth_items_id: 114,
    service_name: "Wash and Iron",
    price: 170,
  },
  {
    id: 115,
    name: "Saree S Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 115,
    service_name: "Wash and Iron",
    price: 240,
  },
  {
    id: 116,
    name: "Jacket Normal",
    image: "jacket.png",
    cloth_items_id: 116,
    service_name: "Wash and Iron",
    price: 170,
  },
  {
    id: 117,
    name: "Borka Normal",
    image: "borka.png",
    cloth_items_id: 117,
    service_name: "Wash and Iron",
    price: 70,
  },
  {
    id: 118,
    name: "Ladies Pants",
    image: "Ladies Pant.png",
    cloth_items_id: 118,
    service_name: "Wash and Iron",
    price: 40,
  },
  {
    id: 119,
    name: "Apron",
    image: "Apron.png",
    cloth_items_id: 119,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 120,
    name: "Parda (Less than 3'')",
    image: "Parda.png",
    cloth_items_id: 120,
    service_name: "Wash and Iron",
    price: 60,
  },
  {
    id: 121,
    name: "Plazu",
    image: "Plazu.png",
    cloth_items_id: 121,
    service_name: "Wash and Iron",
    price: 40,
  },
  {
    id: 122,
    name: "Kammez (3 piece)",
    image: "kameez.jpg",
    cloth_items_id: 122,
    service_name: "Wash and Iron",
    price: 120,
  },
  {
    id: 123,
    name: "Shirt",
    image: "Shirt.png",
    cloth_items_id: 123,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 124,
    name: "Lungi",
    image: "Lungi.png",
    cloth_items_id: 124,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 125,
    name: "Sufari Suit (2 piece)",
    image: "Sufari Top.png",
    cloth_items_id: 125,
    service_name: "Wash and Iron",
    price: 130,
  },
  {
    id: 126,
    name: "Borka Special",
    image: "borka.png",
    cloth_items_id: 126,
    service_name: "Wash and Iron",
    price: 110,
  },
  {
    id: 127,
    name: "Parda (Less than 4'')",
    image: "Parda.png",
    cloth_items_id: 127,
    service_name: "Wash and Iron",
    price: 210,
  },
  {
    id: 128,
    name: "Pajama",
    image: "Pajama 1.png",
    cloth_items_id: 128,
    service_name: "Wash and Iron",
    price: 40,
  },
  {
    id: 129,
    name: "Bed Sheet (small)",
    image: "Bed Sheet.png",
    cloth_items_id: 129,
    service_name: "Wash and Iron",
    price: 70,
  },
  {
    id: 130,
    name: "Orna/Dopatta",
    image: "Orna.png",
    cloth_items_id: 130,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 131,
    name: "Pillow Cover",
    image: "Pillow Cover.png",
    cloth_items_id: 131,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 132,
    name: "Saree Georgette",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 132,
    service_name: "Wash and Iron",
    price: 150,
  },
  {
    id: 133,
    name: "Ladies Jacket Wool",
    image: "Ladies Jacket.png",
    cloth_items_id: 133,
    service_name: "Wash and Iron",
    price: 110,
  },
  {
    id: 134,
    name: "Hijab",
    image: "hijab.png",
    cloth_items_id: 134,
    service_name: "Wash and Iron",
    price: 40,
  },
  {
    id: 135,
    name: "Blaouse",
    image: "blaouse.png",
    cloth_items_id: 135,
    service_name: "Wash and Iron",
    price: 20,
  },
  {
    id: 136,
    name: "Ladies Top",
    image: "Ladies-Top.jpg",
    cloth_items_id: 136,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 137,
    name: "Pants/Trouser",
    image: "jeans.png",
    cloth_items_id: 137,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 138,
    name: "Kammez (2 piece)",
    image: "kameez.jpg",
    cloth_items_id: 138,
    service_name: "Wash and Iron",
    price: 80,
  },
  {
    id: 139,
    name: "Jeans",
    image: "jeans.png",
    cloth_items_id: 139,
    service_name: "Wash and Iron",
    price: 30,
  },
  {
    id: 140,
    name: "Panjabi Heavey",
    image: "Panjabi.png",
    cloth_items_id: 140,
    service_name: "Wash and Iron",
    price: 50,
  },
  {
    id: 141,
    name: "Table Cloth",
    image: "table cloths.png",
    cloth_items_id: 141,
    service_name: "Wash and Iron",
    price: 45,
  },
  {
    id: 142,
    name: "Bed Sheet (large)",
    image: "Bed Sheet.png",
    cloth_items_id: 142,
    service_name: "Wash and Iron",
    price: 100,
  },
  {
    id: 143,
    name: "Mujib Coat",
    image: "Mujib Coat.png",
    cloth_items_id: 143,
    service_name: "Wash and Iron",
    price: 55,
  },
  {
    id: 144,
    name: "Parda (less than 6'')",
    image: "Parda.png",
    cloth_items_id: 144,
    service_name: "Wash and Iron",
    price: 330,
  },
  {
    id: 145,
    name: "Ladies Jacket",
    image: "Ladies Jacket.png",
    cloth_items_id: 145,
    service_name: "Wash and Iron",
    price: 170,
  },
  {
    id: 146,
    name: "Kammez",
    image: "kameez.jpg",
    cloth_items_id: 146,
    service_name: "Wash and Iron",
    price: 80,
  },
  {
    id: 147,
    name: "Parda (Less than 5'')",
    image: "Parda.png",
    cloth_items_id: 147,
    service_name: "Wash and Iron",
    price: 270,
  },
  {
    id: 148,
    name: "T-Shirt",
    image: "t-shirt.png",
    cloth_items_id: 148,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 149,
    name: "Ladies Apron",
    image: "Apron.png",
    cloth_items_id: 149,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 150,
    name: "Saree Cotton",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 150,
    service_name: "Dry Wash and Iron",
    price: 140,
  },
  {
    id: 151,
    name: "Coat/Blazer",
    image: "default.png",
    cloth_items_id: 151,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 152,
    name: "Track Suit (2 piece)",
    image: "Track Suit.png",
    cloth_items_id: 152,
    service_name: "Dry Wash and Iron",
    price: 120,
  },
  {
    id: 153,
    name: "Sufari Top",
    image: "Sufari Top.png",
    cloth_items_id: 153,
    service_name: "Dry Wash and Iron",
    price: 100,
  },
  {
    id: 154,
    name: "Blanket (single)",
    image: "Blanket.png",
    cloth_items_id: 154,
    service_name: "Dry Wash and Iron",
    price: 350,
  },
  {
    id: 155,
    name: "Panjabi",
    image: "Panjabi.png",
    cloth_items_id: 155,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 156,
    name: "Saree Jamdani Silk",
    image: "Jamdani Sharee slik.png",
    cloth_items_id: 156,
    service_name: "Dry Wash and Iron",
    price: 180,
  },
  {
    id: 157,
    name: "Saree Jamdani Cotton",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 157,
    service_name: "Dry Wash and Iron",
    price: 180,
  },
  {
    id: 158,
    name: "Ladies Suit (2Piece)",
    image: "Ladies Suit.png",
    cloth_items_id: 158,
    service_name: "Dry Wash and Iron",
    price: 250,
  },
  {
    id: 159,
    name: "Ladies Shirt",
    image: "Ladies Shirt.png",
    cloth_items_id: 159,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 160,
    name: "Ladies Pajama",
    image: "Pajama.png",
    cloth_items_id: 160,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 161,
    name: "Jamdani Silk Shari",
    image: "Jamdani Sharee slik.png",
    cloth_items_id: 161,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 162,
    name: "Saree S Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 162,
    service_name: "Dry Wash and Iron",
    price: 300,
  },
  {
    id: 163,
    name: "Suit (3 piece)",
    image: "default.png",
    cloth_items_id: 163,
    service_name: "Dry Wash and Iron",
    price: 280,
  },
  {
    id: 164,
    name: "Jacket Normal",
    image: "jacket.png",
    cloth_items_id: 164,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 165,
    name: "Blanket (Dauble)",
    image: "Blanket.png",
    cloth_items_id: 165,
    service_name: "Dry Wash and Iron",
    price: 450,
  },
  {
    id: 166,
    name: "Borka Normal",
    image: "borka.png",
    cloth_items_id: 166,
    service_name: "Dry Wash and Iron",
    price: 80,
  },
  {
    id: 167,
    name: "Ladies Pants",
    image: "Ladies Pant.png",
    cloth_items_id: 167,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 168,
    name: "Suit (2 piece)",
    image: "default.png",
    cloth_items_id: 168,
    service_name: "Dry Wash and Iron",
    price: 250,
  },
  {
    id: 169,
    name: "Apron",
    image: "Apron.png",
    cloth_items_id: 169,
    service_name: "Dry Wash and Iron",
    price: 70,
  },
  {
    id: 170,
    name: "Maxi",
    image: "Maxi.png",
    cloth_items_id: 170,
    service_name: "Dry Wash and Iron",
    price: 80,
  },
  {
    id: 171,
    name: "Ladies Suit (2Piece)",
    image: "Ladies Suit.png",
    cloth_items_id: 171,
    service_name: "Dry Wash and Iron",
    price: 250,
  },
  {
    id: 172,
    name: "Plazu",
    image: "Plazu.png",
    cloth_items_id: 172,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 173,
    name: "Kammez (3 piece)",
    image: "kameez.jpg",
    cloth_items_id: 173,
    service_name: "Dry Wash and Iron",
    price: 180,
  },
  {
    id: 174,
    name: "Shirt",
    image: "Shirt.png",
    cloth_items_id: 174,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 175,
    name: "Blanket (dauble)",
    image: "Blanket.png",
    cloth_items_id: 175,
    service_name: "Dry Wash and Iron",
    price: 400,
  },
  {
    id: 176,
    name: "Lungi",
    image: "Lungi.png",
    cloth_items_id: 176,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 177,
    name: "Sufari Suit (2 piece)",
    image: "Sufari Top.png",
    cloth_items_id: 177,
    service_name: "Dry Wash and Iron",
    price: 170,
  },
  {
    id: 178,
    name: "Borka Special",
    image: "borka.png",
    cloth_items_id: 178,
    service_name: "Dry Wash and Iron",
    price: 100,
  },
  {
    id: 179,
    name: "Saree Benarasi Katan",
    image: "Jamdani Sharee Cotton.png",
    cloth_items_id: 179,
    service_name: "Dry Wash and Iron",
    price: 400,
  },
  {
    id: 180,
    name: "Jacket Leather",
    image: "jacket.png",
    cloth_items_id: 180,
    service_name: "Dry Wash and Iron",
    price: 500,
  },
  {
    id: 181,
    name: "Pajama",
    image: "Pajama.png",
    cloth_items_id: 181,
    service_name: "Dry Wash and Iron",
    price: 50,
  },
  {
    id: 182,
    name: "Ladies Suit",
    image: "Ladies Suit.png",
    cloth_items_id: 182,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 183,
    name: "Tie",
    image: "Tie.png",
    cloth_items_id: 183,
    service_name: "Dry Wash and Iron",
    price: 30,
  },
  {
    id: 184,
    name: "Bed Sheet (small)",
    image: "Bed Sheet.png",
    cloth_items_id: 184,
    service_name: "Dry Wash and Iron",
    price: 80,
  },
  {
    id: 185,
    name: "Orna/Dopatta",
    image: "Orna.png",
    cloth_items_id: 185,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 186,
    name: "Pillow Cover",
    image: "Pillow Cover.png",
    cloth_items_id: 186,
    service_name: "Dry Wash and Iron",
    price: 40,
  },
  {
    id: 187,
    name: "Saree Georgette",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 187,
    service_name: "Dry Wash and Iron",
    price: 150,
  },
  {
    id: 188,
    name: "Ladies Jacket Wool",
    image: "Ladies Jacket.png",
    cloth_items_id: 188,
    service_name: "Dry Wash and Iron",
    price: 150,
  },
  {
    id: 189,
    name: "Saree Silk",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 189,
    service_name: "Dry Wash and Iron",
    price: 190,
  },
  {
    id: 190,
    name: "Hijab",
    image: "hijab.png",
    cloth_items_id: 190,
    service_name: "Dry Wash and Iron",
    price: 50,
  },
  {
    id: 191,
    name: "Over Coat",
    image: "default.png",
    cloth_items_id: 191,
    service_name: "Dry Wash and Iron",
    price: 150,
  },
  {
    id: 192,
    name: "Blaouse",
    image: "blaouse.png",
    cloth_items_id: 192,
    service_name: "Dry Wash and Iron",
    price: 50,
  },
  {
    id: 193,
    name: "Saree Benarasi",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 193,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 194,
    name: "Ladies Top",
    image: "Ladies-Top.jpg",
    cloth_items_id: 194,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 195,
    name: "Pants/Trouser",
    image: "jeans.png",
    cloth_items_id: 195,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 196,
    name: "Kammez (2 piece)",
    image: "kameez.jpg",
    cloth_items_id: 196,
    service_name: "Dry Wash and Iron",
    price: 120,
  },
  {
    id: 197,
    name: "Jeans",
    image: "jeans.png",
    cloth_items_id: 197,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 198,
    name: "Shorts",
    image: "shorts.png",
    cloth_items_id: 198,
    service_name: "Dry Wash and Iron",
    price: 40,
  },
  {
    id: 199,
    name: "Baby Suit (2 Piece)",
    image: "default.png",
    cloth_items_id: 199,
    service_name: "Dry Wash and Iron",
    price: 100,
  },
  {
    id: 200,
    name: "Panjabi Heavey",
    image: "Panjabi.png",
    cloth_items_id: 200,
    service_name: "Dry Wash and Iron",
    price: 80,
  },
  {
    id: 201,
    name: "Saree Half Silk",
    image: "Geoarzte Sharee.png",
    cloth_items_id: 201,
    service_name: "Dry Wash and Iron",
    price: 160,
  },
  {
    id: 202,
    name: "Bed Sheet (large)",
    image: "Bed Sheet.png",
    cloth_items_id: 202,
    service_name: "Dry Wash and Iron",
    price: 100,
  },
  {
    id: 203,
    name: "Blanket (single)",
    image: "Blanket.png",
    cloth_items_id: 203,
    service_name: "Dry Wash and Iron",
    price: 400,
  },
  {
    id: 204,
    name: "Mujib Coat",
    image: "Mujib Coat.png",
    cloth_items_id: 204,
    service_name: "Dry Wash and Iron",
    price: 70,
  },
  {
    id: 205,
    name: "Ladies Jacket",
    image: "Ladies Jacket.png",
    cloth_items_id: 205,
    service_name: "Dry Wash and Iron",
    price: 200,
  },
  {
    id: 206,
    name: "Kammez",
    image: "kameez.jpg",
    cloth_items_id: 206,
    service_name: "Dry Wash and Iron",
    price: 60,
  },
  {
    id: 207,
    name: "Shaal",
    image: "Shaal.png",
    cloth_items_id: 207,
    service_name: "Dry Wash and Iron",
    price: 120,
  },
  {
    id: 208,
    name: "",
    image: "default.png",
    cloth_items_id: 208,
    service_name: "Dry Wash and Iron",
    price: 80,
  },
  {
    id: 209,
    name: "T-Shirt",
    image: "t-shirt.png",
    cloth_items_id: 209,
    service_name: "Wash and Iron",
    price: 50,
  },
  {
    id: 210,
    name: "",
    image: "default.png",
    cloth_items_id: 210,
    service_name: "",
    price: 0,
  },
];

export default productsArr;
