import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { productFetchAsync, productUpdatehAsync, uploadImageClothAsync } from '../../features/Product/productSlice';
import toast from 'react-hot-toast';

const ModalService = ({data}) => {
    const {image, name, price, service_name, id,cloth_items_id} = data || {}
    console.log(name);
    const [isEditmode, setisEditmode] = useState(false);
    // const dispatch = useDispatch()
    // const [productData, setproductData] = useState({
    //     name:name,
    //     price:price,
    //     service_name:service_name,
    //     cloth_id:cloth_items_id,
    // });

    // const daveData = ()=>{
    //     dispatch(productUpdatehAsync(productData)).unwrap().then((res)=>{
    //       if(res?.status!==200){
    //         toast.error("Update Failed");
    //          return false;
    //       }
    //       toast.success("Update Succesfull");
    //       dispatch(productFetchAsync())
    //     })
    // }

    // const imageUpload = (imageFile)=>{
    //     dispatch(uploadImageClothAsync({image:imageFile,cloth_id:cloth_items_id})).unwrap().then((res)=>{
    //         if(res?.status!==200){
    //             toast.error("Update Failed");
    //              return false;
    //         }
    //         toast.success("Upload Succesfull");
    //         dispatch(productFetchAsync())
    //     })
    //     // console.log(imageFile);
    // }
   
    return (
        <tr>
            
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex items-center">
                    <div className="flex-shrink-0 w-10 h-10 relative">
                        <img className="w-full h-full rounded-full"
                            src={'https://dhopaelo.com/app/cloths/'+image}
                            alt="" />
                        
                    </div>
                    <div className="ml-3">
                        {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap border-b">
                        {id}. {name}
                        </p>}

                        
                        
                    </div>
                </div>
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap">{price}</p>}
               
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap">
                    {service_name}
                </p>}
             
            </td>
            
        </tr>
    );
}

export default ModalService;
