import React,{} from 'react';
import Hometable from '../Components/Home/Hometable';


const Home = () => {
    
    return (
       
            <div className='w-full lg:w-10/12 overflow-hidden h-screen overflow-y-auto mt-10 lg:mt-0 transition-all'>
                <Hometable/>
            </div>
            
            
        
    );
}

export default Home;
