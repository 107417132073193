import React, { useEffect, useState } from 'react';
import moment from 'moment'
import OrderModal from './OrderModal';
import { useDispatch, useSelector } from 'react-redux';
import { orderUpdateAsync } from '../../features/order/orderSlice';
import toast from 'react-hot-toast';





const TableRowOrders = ({data,statusFilter}) => {
    const dispatch = useDispatch();
    const {orders,isLoading,isError, error} = useSelector((state)=>state?.orders)
    const [stageChnage, setstageChnage] = useState('');
    const [controllModal, setcontrollModal] = useState(false);
    const {username, phone, email,products,delivery_id,pickup_id, vendor,total_account,status,id,customer_id, payment_status, payment_type,location_id, image, order_date, transaction_id} = data || {}
    const modalControlFun = ()=>{
        setcontrollModal((prev)=>!prev)
    }
    const notify = () => toast('Here is your toast.');
    // useEffect(() => {
    //     console.log(stageChnage);
    // }, [stageChnage]);

   


    const handleChnage = ()=>{
        if(isLoading || stageChnage===''){
            toast.error("Select the Status First")
            return 0;
        }
        const data={
            status:stageChnage,
            id, 
            customer_id,
            email,
            username
        }

        toast.promise(
            dispatch(orderUpdateAsync(data)),
            {
            loading: 'Saving...',
            success: <b>Update saved!</b>,
            error: <b>Could not save.</b>,
            }
        );
        if(error){
            toast.error("Update Failed.")
        }
        setstageChnage('')

        
        
    }
    

    const statusColor = {
        'Washing': 'blue',
        'Pending': 'amber',
        'Ongoing':'green',
        'Drying': 'gray',
        'Delivering': 'yellow',
        'Deliverd':'lime',
        'Completed':'emerald',
        'Cleaning':'cyan',
        'Canceled':'red'
    }
    return (
        <>
        
            {controllModal&&<div className='fixed top-0 left-0 w-full h-screen overflow-y-scroll' style={{backgroundColor:'rgba(0,0,0,0.8)', zIndex:99}}>
                <p className='text-gray-300 absolute top-1 left-5 cursor-pointer z-50' onClick={modalControlFun}>Back</p>
                <OrderModal data={data} modalControlFun={modalControlFun} />
            </div>}
            <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-full h-full rounded-full"
                                src={!image?"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80":'https://dhopaelo.com/app/profile/'+image}
                                alt="" />
                        </div>
                        <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                                {username}
                            </p>
                            <p className="text-gray-500 whitespace-no-wrap">
                                {phone}
                            </p>
                            <p className="text-gray-500 whitespace-no-wrap">
                                {email}
                            </p>
                            <p className="text-gray-500 whitespace-no-wrap text-xs">
                                {moment(order_date).fromNow()} @ ({order_date})
                            </p>
                        </div>
                    </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className='flex flex-wrap mb-1' style={{fontSize:'10px',lineHeight:1.5,width: '250px'}}>
                    {
                        JSON.parse(products)?.map((product,index)=>{
                            return <div key={index} className='p-2 rounded-sm border-gray-800 m-1 bg-slate-200'>
                                <p>{product?.name} - ৳{product?.price} x {product?.quantity}</p>
                            
                                <p>For: <b>{product?.service_name}</b></p>
                            </div>
                        })
                    }
                    
                    </div>
                    <hr></hr>
                    <p className='text-xs'>Total: <b>{total_account}</b></p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className='flex'>
                        <div className='p-2 rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'10px', lineHeight:1.5}}>
                            <p className='text-gray-500'>Pick_up on:</p>
                            <p>{moment(JSON.parse(pickup_id)?.date).format('ll')}</p>
                            <p>{JSON.parse(pickup_id)?.time}</p>
                        </div>
                        <div className='p-2 rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'10px',lineHeight:1.5}}>
                            <p className='text-gray-500'>Delivery on:</p>
                            <p>{moment(JSON.parse(delivery_id)?.date).format('ll')}</p>
                            <p>{JSON.parse(delivery_id)?.time}</p>
                        </div>
                    </div>
                    <div className='p-2 rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'10px',lineHeight:1.5}}>
                        <p className='text-gray-500'>Delivery & Pickup address:</p>
                        <p>{JSON.parse(location_id)?.address_line}, {JSON.parse(location_id)?.region}, {JSON.parse(location_id)?.district}</p>
                        {/* <p>{JSON.parse(location_id)?.time}</p> */}
                    </div>
                    
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div style={{minWidth:'190px'}}>
                        <div className='p-2 rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'10px', lineHeight:1.5}}>
                            {JSON.parse(vendor)?.id?<b>({JSON.parse(vendor)?.id}){JSON.parse(vendor)?.name}- </b>:<b>Dhopaelo Service</b>}
                            
                            <i> {JSON.parse(vendor)?.address}</i>
                        </div>
                        <span
                            className={`relative inline-block px-3 font-semibold text-lime-900 leading-tight pb-1`}>
                            <span aria-hidden
                                className={`absolute inset-0 bg-lime-500 opacity-50 rounded-full`}></span>
                            <span className="relative" style={{fontSize:'10px'}}>{status}</span>
                        </span>
                        <span
                            className={`relative inline-block px-3 font-semibold text-orange-900 leading-tight pb-1 mx-1`}>
                            <span aria-hidden
                                className={`absolute inset-0 bg-orange-500 opacity-50 rounded-full`}>
                            </span>
                            <span className="relative" style={{fontSize:'10px'}}>{payment_type==='Payment'&&transaction_id?'PAID':'UNPAID'}</span>
                        </span>
                        <span
                            className={`relative inline-block px-3 font-semibold text-teal-900 leading-tight pb-1`}>
                            <span aria-hidden
                                className={`absolute inset-0 bg-teal-500 opacity-50 rounded-full`}></span>
                            <span className="relative" style={{fontSize:'10px'}}>{payment_type==='Payment'?'Online':payment_type}</span>
                        </span>
                    </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span onClick={modalControlFun} className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight cursor-pointer">
                        <span aria-hidden
                            className="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                        <span className="relative">More</span>
                    </span>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className='w-36'>
                        <select
                            onChange={(v)=>setstageChnage(v.target.value)}
                            className="appearance-none h-full rounded-r border-t sm:rounded-r sm:border-r border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none border-l focus:bg-white focus:border-gray-500 mb-1">
                            <option disabled selected>Change Status</option>
                            <option value={'Pending'}>Pending</option>
                            <option value={'Ongoing'}>Ongoing</option>
                            <option value={'Washing'}>Washing</option>
                            <option value={'Cleaning'}>Cleaning</option>
                            <option value={'Drying'}>Drying</option>
                            <option value={'Delivering'}>Delivering</option>
                            <option value={'Completed'}>Deliverd</option>
                            <option value={'Completed'}>Completed</option>
                            <option value={'Canceled'}>Cancel</option>
                        </select>
                        <span
                            disabled={isLoading}
                            onClick={()=>handleChnage()}
                            className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight mt-1 cursor-pointer">
                            <span aria-hidden
                                className="absolute inset-0 bg-green-500 opacity-50 rounded-sm"></span>
                            <span className="relative">{isLoading?'Updating...':'Update'}</span>
                        </span>
                    </div>
                </td>
            </tr>
            
        </>
    );
}

export default TableRowOrders;
