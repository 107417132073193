import React from 'react';

const Loading = ({message}) => {
    return (
        <div className='flex h-screen w-full justify-center items-center fixed top-0 left-0'>
            <p>{message}</p>
        </div>
    );
}

export default Loading;
