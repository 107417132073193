import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice';
import orderReducer from '../features/order/orderSlice'
import loginReducer from '../features/login/loginSlice'
import productsReducer from '../features/Product/productSlice'
import vendorReducer from '../features/vendor/vendorSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    users:userReducer,
    orders:orderReducer,
    login:loginReducer,
    product:productsReducer,
    vendors:vendorReducer
  },
});
