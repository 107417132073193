import moment from 'moment';
import React from 'react';
import logo from '../../images/logo.png'


const OrderModal = ({data,modalControlFun}) => {
    const {username, phone, email,products,delivery_id,pickup_id, vendor,total_account,status,id,customer_id, payment_status,payment_type, location_id, note} = data || {}
    let motTaka = 0;

    const saveAsPDF = () => {
        
        window.print()
      };

    return (
        
        <div className="w-full" id='invoice_body'>
            <div className="bg-white w-full p-8">
                <div className="flex justify-between items-center border-b">
                    <div>
                        <h1 className="text-3xl font-semibold cursor-pointer" onClick={saveAsPDF}>Invoice</h1>
                        <p className="text-gray-600">Date: {moment().format('ll')}</p>
                    </div>
                    <div className='text-center' style={{fontSize:'10px'}}>
                        <b style={{fontSize:'12px'}}>Dhopaelo</b>
                        <p>info@dhopaelo.com</p>
                        <address>Shialbari Graveyard, Level:4,Plot:25, Road:04, Rupnagar Rd, Dhaka </address>
                    </div>
                    <div>
                        <div className='flex justify-end'>
                            <img src={logo} alt="Your Logo" className="h-16"/>
                        </div>
                    </div>
                </div>

                <div className="mt-6">
                    <div className="mb-4">
                        <p className="text-lg font-semibold">Customer Information</p>
                        <p><span className="font-semibold">Name:</span> {username}</p>
                        <p><span className="font-semibold">Phone:</span> {phone}</p>
                        <p><span className="font-semibold">Email:</span> {email}</p>
                    </div>
                    <div className='flex'>
                        <div className='p-2 border rounded-sm border-gray-800 m-1  w-fit' style={{minWidth:'120px', fontSize:'14px', lineHeight:1.5, backgroundColor:'#e2e8f0', }}>
                            <p className='text-gray-500'>Pick_up on:</p>
                            <p>{moment(JSON.parse(pickup_id)?.date).format('ll')}</p>
                            <p>{JSON.parse(pickup_id)?.time}</p>
                            
                        </div>
                        <div className='p-2 border rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'14px',lineHeight:1.5, backgroundColor:'#e2e8f0'}}>
                            <p className='text-gray-500'>Delivery on:</p>
                            <p>{moment(JSON.parse(delivery_id)?.date).format('ll')}</p>
                            <p>{JSON.parse(delivery_id)?.time}</p>
                        </div>
                        <div className='p-2 border rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'14px',lineHeight:1.5, backgroundColor:'#e2e8f0'}}>
                            <p className='text-gray-500'>Delivery & Pick_up Address:</p>
                            <i>{JSON.parse(location_id)?.address_line}, {JSON.parse(location_id)?.region}, {JSON.parse(location_id)?.district}</i>
                        </div>
                        
                    </div>
                    <div>
                        <p className="text-lg font-semibold">Order Details</p>
                        <div className="mt-2">
                            <table className='rounded-sm w-full' style={{border: '1px solid gray'}}>
                                <thead border={1}>
                                    <th className='p-1'>Item</th>
                                    <th className='p-1'>Price</th>
                                    <th className='p-1'>Quantity</th>
                                    <th className='p-1'>Sub total</th>
                                    <th className='p-1'>Pay Status</th>
                                    <th className='p-1'>Pay Type</th>
                                    <th className='p-1'>Total</th>
                                </thead>
                                <tbody border={1}>
                                {
                                    JSON.parse(products)?.map((product,index)=>{
                                        motTaka = motTaka+(Number(product?.price)*Number(product?.quantity))
                                        return <tr key={index}>
                                            <td className='p-1'>{product?.name}</td>
                                            <td className='p-1'>৳ {product?.price}</td>
                                            <td className='p-1'>{product?.quantity}</td>
                                            <td className='p-1'>{product?.service_name}</td>
                                            <td className='p-1'>{payment_status}</td>
                                            <td className='p-1'>{payment_type==='Payment'?'Online':'COD'}</td>
                                            <td className='p-1'>৳ {product?.price*product?.quantity}</td>
                                        </tr>
                                    })
                                }
                                    
                                    <tr>
                                        <td className='p-1' colSpan={7}></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='p-1' colSpan={6}>Sub total</td>
                                        <td className='p-1'>৳ {motTaka}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-1' colSpan={6}>(Tax+Vat+Delivery Charge) - discount</td>
                                        <td className='p-1'>৳ {+total_account-motTaka}</td>
                                    </tr>
                                    {/* <tr>
                                        <td className='p-1' colSpan={6}>Coupon</td>
                                        <td className='p-1'>Coupon</td>
                                    </tr> */}
                                    <tr>
                                        <td className='p-1' colSpan={6}><b>Grand Total</b></td>
                                        <td className='p-1'><b>৳ {total_account}</b></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                      
                    </div>
                </div>

                <div className="mt-6 flex">
                    
                    <div className='p-2 border rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{ fontSize:'14px',lineHeight:1.5, backgroundColor:'#e2e8f0'}}>
                        <p className='text-gray-500'>Note:</p>
                        <p>{note}</p>
                    </div>
                    <div className='p-2 border rounded-sm border-gray-800 m-1 bg-slate-200 w-fit' style={{minWidth:'120px', fontSize:'14px', lineHeight:1.5, backgroundColor:'#e2e8f0'}}>
                        <p className='text-gray-500'>Selected Vendor:</p>

                        {JSON.parse(vendor)?.id?<b>({JSON.parse(vendor)?.id}){JSON.parse(vendor)?.name}- </b>:<b>Dhopaelo Service</b>}
                        
                        <i> {JSON.parse(vendor)?.address}</i>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default OrderModal;
