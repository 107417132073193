import axios from "../../utils/axios"

export const getprodunctData = async ()=>{
    const responce = await axios.get('/all_cloth_items');
    return responce?.data?.result;

}

export const UpdateprodunctData = async (data)=>{
    const responce = await axios.post(`/all_cloth_item_update/${data?.cloth_id}`,data);
    return responce?.data;

}

export const UpdateprodunctImage = async (data)=>{
    const formData = new FormData();
    formData.append('image',data?.image);

    const responce = await axios.post(`/upload_cloth_image/${data?.cloth_id}`,formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return responce?.data;

}