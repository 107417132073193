import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { UpdateprodunctData, UpdateprodunctImage, getprodunctData } from "./productAPI";

const initialState ={
    isLoading:false,
    isError:false,
    error:null,
    products:[]
}


//async thunk
export const productFetchAsync = createAsyncThunk(
    'product/getorder',
    async ()=>{
        const result = await getprodunctData();
        return result;
    }
    )
//async thunk
export const productUpdatehAsync = createAsyncThunk(
    'product/updateorder',
    async (data)=>{
        const result = await UpdateprodunctData(data);
        return result;
    }
    )
export const uploadImageClothAsync = createAsyncThunk(
    'product/clothsImageupload',
    async (data)=>{
        const result = await UpdateprodunctImage(data)
        return result;
    }
    )


export const productSlice = createSlice({
    name:'products',
    initialState,
    extraReducers: (builder)=>{
        builder
            .addCase(productFetchAsync.pending, (state)=>{
                state.isLoading = true;
            })
            .addCase(productFetchAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                state.products = action?.payload;
            })
            .addCase(productFetchAsync.rejected,(state,action)=>{
                state.isLoading=false;
                state.isError = true;
                state.error = action.error.message;
                state.products = null
            })
            .addCase(productUpdatehAsync.pending, (state)=>{
                state.isLoading = true;
            })
            .addCase(productUpdatehAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                // state.products = action?.payload;
            })
            .addCase(productUpdatehAsync.rejected,(state,action)=>{
                state.isLoading=false;
                state.isError = true;
                state.error = action.error.message;
                state.products = null
            })
            .addCase(uploadImageClothAsync.pending, (state)=>{
                state.isLoading = true;
            })
            .addCase(uploadImageClothAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                // state.products = action?.payload;
            })
            .addCase(uploadImageClothAsync.rejected,(state,action)=>{
                state.isLoading=false;
                state.isError = true;
                state.error = action.error.message;
                state.products = null
            })
    }
})

export default productSlice.reducer;