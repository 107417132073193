import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { userData } from "./userAPI";

const initialState = {
    users:[],
    isLoading:false,
    isError: false,
    error:''
}


//async thunk
export const userFetchAsync = createAsyncThunk(
    'user/fetchuser',
    async ()=>{
        const responce = await userData();

        return responce;
    }
);

export const userSlice = createSlice({
    name:'users',
    initialState,
    extraReducers: (builder)=>{
        builder
            .addCase(userFetchAsync.pending,(state)=>{
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(userFetchAsync.fulfilled, (state, action)=>{
                state.isLoading = false;
                state.users = action?.payload;
            })
            .addCase(userFetchAsync.rejected, (state, action)=>{
                state.users=[];
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;

            })
    }
});



export default userSlice?.reducer;