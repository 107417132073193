import axios from "../../utils/axios"

export const orderData = async ()=>{
    const response = await axios.get('/ordersbyusers');
    return response.data?.result;

}

export const orderUpdate = async (data)=>{
    const {id, customer_id, status} = data;
    const response = await axios.post(`/update_orders_table_dash/${customer_id}/${id}`,data);
    return response.data;

}