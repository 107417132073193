import React, { useEffect, useState } from 'react';
import { API_URL } from '../../utils/lib';
import toast from 'react-hot-toast';

const Allsettings = () => {
    const [isEditmode, setisEditmode] = useState(false);
    const [delivery_charge, setdelivery_charge] = useState(0);
    const [tax, settax] = useState(0);

    const fetchCharges = ()=>{
        fetch(API_URL+"/delivertaxdata")
        .then((res)=>res.json())
        .then((charges)=>{
            setdelivery_charge(charges?.result[0]?.delivery_charge)
            settax(charges?.result[0]?.tax)
            console.log(charges?.result[0]);
        })
    }

    const updateCharges = ()=>{
        fetch(API_URL+"/delivertax/1",{
            method:'POST',
            headers:{
                'Content-Type':'Application/JSON'
            },
            body:JSON.stringify({
                delivery_charge,
                tax
            })
        })
        .then((res)=>res.json())
        .then((charges)=>{
            console.log(charges);
            fetchCharges()
            setisEditmode((prev)=>!prev)
            toast.success("Updated")
        })
        .catch((err)=>{
            toast.error("Not Updated")
        })
    }

    useEffect(() => {
        fetchCharges()
    }, []);
    return (
        <div className='p-5'>
            <div className='bg-gray-400 rounded p-2 bold'>
                <p>Update Delivery Charge</p>
            </div>
            <div>
                <div className="flex items-center bg-gray-100 p-3 py-5">
                    <div className="ml-3 w-2/6 bg-gray-200 border border-gray-300 rounded-md p-3 ">
                        {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap border-b">
                        Delivery Charge={delivery_charge}
                        </p>}

                        {isEditmode&&<input onChange={(v)=>setdelivery_charge(v.target.value)} className='border-2 w-full p-2' placeholder='Cloths name' value={delivery_charge} />}
                        
                    </div>
                    <div className="ml-3 w-2/6 bg-gray-200 border border-gray-300 rounded-md p-3">
                        {!isEditmode&&<p className="text-gray-900 whitespace-no-wrap border-b">
                        Tax Charge={tax}
                        </p>}

                        {isEditmode&&<input onChange={(v)=>settax(v.target.value)} className='border-2 w-full p-2' placeholder='Cloths name' value={tax} />}
                        
                    </div>
                    <div>
                        {!isEditmode&&<span
                        className="relative inline-block bg-blue-400 rounded ml-2 px-7 py-4 font-semibold text-green-900 leading-tight cursor-pointer" onClick={()=>setisEditmode((prev)=>!prev)}>
                            <span className="relative">Edit</span>
                        </span>}

                        {isEditmode&&<span
                        className="relative inline-block bg-green-400 rounded ml-2 px-7 py-4 font-semibold text-green-900 leading-tight cursor-pointer" onClick={()=>updateCharges()}>
                            <span className="relative">Save</span>
                        </span>}
                        {isEditmode&&<span
                        className="relative inline-block bg-red-400 rounded ml-2 px-7 py-4 font-semibold text-green-900 leading-tight cursor-pointer" onClick={()=>setisEditmode((prev)=>!prev)}>
                            <span className="relative">Cancel</span>
                        </span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Allsettings;
