import axios from "../../utils/axios"

export const vendorData = async ()=>{
    const response = await axios.get('/vendors_info');
    return response.data;

}

export const vendorUpdate = async (data)=>{
    const {data:data2} = data
    const response = await axios.post('/vendors_service_list/'+data?.id,data2);
    console.log(typeof data2 ,data2);
    return response.data;

}